<template>
  <div class="app">
    <Header />
    <!-- enter-active-class="animate__animated animate__fadeInLeft"
      leave-active-class="animate__animated animate__fadeInLeft" -->
    <transition
      name="slide"
    >
      <router-view />
    </transition>
    <footer-vue />
  </div>
</template>

<script>
import FooterVue from './Footer.vue'
import Header from '@/components/Header.vue'
export default {
  name: 'Index',
  components: {
    FooterVue,
    Header
  },
  methods: {
    openFun (obj) {
      this.$router.push({ name: obj })
    }
  }
}
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}
.slide-enter,
.slide-leave-to {
  margin-left: -200px;
}
</style>
