<template>
  <div
    class="Header"
    ref="heder_top"
    :style="
      'background: url(' +
        bannerPic +
        ')  center center / cover no-repeat,linear-gradient(104.59deg, rgba(0, 62, 228, 0.8) -0.6%, rgba(27, 86, 252, 0.8) 100.85%) ;'
    "
  >
  
    <div class="Header_box flex_content" :class="{ fiexd: isFiexd,actived_than_head:isScrollToContent }">
      <div class="Header_left" @click.stop="toHome">
       
        <img :src="isScrollToContent?require('@/assets/logo/new-logo-scrolltext.png'):require('@/assets/logo/new-logo-text.png')" alt="" />
        <slot name="topLeft"></slot>
      </div>
      <section class="flex_content Header_right"  ref="box">
        <li
          class="labels"
          v-for="(item, idx) in list"
          :key="idx"
          @click.stop="tabClick(item, idx)"
        >
          <span
            class="label_context"
            :class="{
              actived: idx === crtItem.idx || crtItem.moveIndx === idx,
            }"
          >
            <span v-if="idx === 4" class="lang_icon">
              <span class="iconfont icon-diqiu icon-logo" :class="isScrollToContent ? 'icon-active' : ''"></span>
              </span
            >
            <span class="icon-text">{{ idx === 4 ? langLabel : item.label }}</span>
          </span>
          <div
            class="detail_box"
            :class="{smallBox:idx === 4}"
            v-if="item.children && item.children.length > 0"
          >
            <span
              v-for="(t, i) in item.children"
              :class="item.language ? 
                  (i == langIndex ? 'actived' : '') : (i == childIndex ? 'actived' : '')"
              @click.stop="childClick(t, i, idx)"
              :key="i"
              >{{ t.label }}
              <i v-if="idx !==4" class="iconfont icon-shouyexinwen-xuanfu noneRightArrow" ></i>
              <!-- :class="i == childIndex ? 'noneRightArrow' : 'noneRightArrow'" -->
              </span
            >
          </div>
        </li>
        <!-- TODO: 后续交互线条做准备 -->
        <!-- <div class="line"></div> -->
      </section>
    </div>
    <div
      v-if="isFiexd"
      style="height:78px;width: 100%;background: transparent;"
    ></div>
    <!-- banner 图 -->
    <div
      class="banner"
      :style="'width: 100%;' + 'height:' + bannerHeight + 'px'"
    >
    <!-- animate__animated animate__fadeInDown -->
      <div
        class="home_banner_pic "
        v-if="currentPath === '/home'"
      >
        <img :src="require('@/assets/banner/bannnerlogo.png')" alt="" class="logoCircle">
        <img
          :src="`${type=='CN'?require('@/assets/banner/home_text.png'):require('@/assets/banner/ftlogo.png')}`"
          alt=""
          style="width:845px;height:134px;display:block"
        />
        <p class="top_text_en">Create wealth and pursue excellence</p>
        <!-- <img
          src="@/assets/banner/home_text_en.png"
          alt=""
          style="width:760px;height:39px;display:block;margin:auto"
        /> -->
      </div>
      <!-- animate__animated animate__fadeInDown -->
      <div class="top_text " v-else>
        {{ topText }}
      </div>
    </div>
  </div>
  <div class="imgFiexdBtn" :class="{hideBtn:!isFiexd}" @click="toBack">
  <i class="iconfont icon-fanhuidingbu"></i></div>
</template>

<script>
import HomeBanner from '@/assets/banner/home.jpg'
import ProfileBanner from '@/assets/banner/profile.jpg'
import NewsBanner from '@/assets/banner/newCenter.jpg'
import BrokerBanner from '@/assets/banner/broker.jpg'
import InvestBanner from '@/assets/banner/investment.jpg'
import AssetBanner from '@/assets/banner/assetManage.jpg'
import Disclaimers from '@/assets/banner/banner.jpg'
import Contact from '@/assets/banner/contactUs.jpg'
export default {
  name: 'Header',
  props: {},
  data () {
    return {
      list: [
        { label: this.$t('Home.nav.home'), show: false, path: '/home' },
        { label: this.$t('Home.nav.company'), show: false, path: '/wepage' },
        {
          label: this.$t('Home.nav.serve'),
          show: false,
          language: false,
          // path: "/home",
          children: [
            { label: this.$t('Home.nav.broker'), path: 'dataList1' },
            { label: this.$t('Home.nav.invest'), path: 'dataList2' },
          ]
        },
        { label: this.$t('Home.nav.news'), show: false, path: '/newsCenter' },
        {
          label: '简体中文',
          show: false,
          language: true,
          // path: "/home",
          children: [
            { label: '简体中文', value: 'CN' },
            { label: '繁體中文', value: 'EN' }
          ]
        }
      ],
      crtItem: {
        idx: 0,
        crtInfo: null,
        childIdx: 0,
        show: false,
        moveIndx: ''
      },
      childIndex: null,
      langIndex: null,
      timer: null,
      type: 'CN',
      initial: 20,
      lineDom: null,
      star: 0,
      crtShowIdx: 0,
      bannerHeight: '524',
      bannerPic: HomeBanner,
      topText: '',
      langLabel: localStorage.getItem('langLabel') || '简体中文',
      isFirst: true,
      setLang: false,
      isFiexd: false,
      observer:null,
      isScrollToContent:false, // 判断是否滑动超过头部
    }
  },
  methods: {
    tabClick (item, index, isTo = true) {
      if (item.children && item.children.length > 0) return
      if (item.path && isTo) {
        this.$router.push({ path: item.path })
        if (index != 2) this.childIndex = null
      }
      if (isTo) {
        this.crtItem.idx = index == 4 ? this.crtItem.idx : index
      } else {
        index != 4 && (this.crtItem.moveIndx = index)
      }

      if (item.children) {
        item.show = !item.show
        if (index == 4) {
          this.list[2].show = false
        } else if (index == 2) {
          this.list[4].show = false
        }
      }
      if (index != 4 && index != 2) {
        this.list[2].show = false
        this.list[4].show = false
      }
    },
    moveDom (item, idx) {
      item.show = true
    },
    toBack() {
      document.documentElement.scrollTo({
                top:0,
                behavior:'smooth'
      })
    },
    childClick(item, index, idx) {
      if (idx == 4) {
        this.type = item.value
      } else {
        localStorage.setItem('CIX', index)
        this.childIndex = index
      }
      this.crtItem.show = false
      this.crtItem = Object.assign(this.crtItem, { childIdx: index })
      if (item.path) {
        this.$router.push({
          name: 'CommonBroke',
          params: {
            id: item.path
          }
        })
      }
      // 切换语言
      switch (item.label) {
        case '简体中文':
          // this.langLabel = item.label
          localStorage.setItem('LANGS', 'ch')
          localStorage.setItem('langLabel', '简体中文')
          location.reload()
          break
        case '繁體中文':
          localStorage.setItem('LANGS', 'com')
          localStorage.setItem('langLabel', '繁體中文')
          this.langLabel = item.label
          location.reload()
          break
      }
    },

    toHome () {
      this.$router.push({
        name: 'HomeView'
      })
    },
  },

  mounted () {
    this.$nextTick(() => {
      this.type = this.list[this.list.length - 1].children.filter(
        item => item.label == (localStorage.getItem('langLabel') || '简体中文')
      )[0].value
      this.langIndex = this.type == 'CN' ? 0 : 1
      if (['/commonBroke/dataList1', '/commonBroke/dataList2', ].includes(this.$route.path)) {
        this.childIndex = localStorage.getItem('CIX')
      }
      let labels = document.querySelectorAll('.labels')
      Array.prototype.forEach.call(labels, (k, i) => {
        k.onmouseout = () => {
          this.crtItem.moveIndx = ''
        }
      })
   
      this.observer = new IntersectionObserver((entries, ob) => {
        let entriesOb = entries[0]
        this.isScrollToContent = !entriesOb.isIntersecting
        // entries.forEach((item, i) => {
        //   if (i) {
            // const img = new Image();
            // img.src = item.target.dataset.src 
            // img.onload = () => { 
              // item.target.src = item.target.dataset.src 
              // this.observer.unobserve(item.target)
            //   item.target.removeAttribute('data-src');
            // }
        //   }
        // })
      })
      this.observer.observe(this.$refs.heder_top)
    //   Array.from(document.querySelectorAll('[data-src]')).forEach(item => {
    //     this.observer.observe(item)
    // //  })
    //   let box =null
      window.addEventListener('scroll', e => {
        let top = document.documentElement.scrollTop || document.body.scrollTop
        this.isFiexd = top > 0
        // if (this.isFiexd) {
          // if (!box) {
          //   box = document.createElement('i')
          //   // box.src = `${require('@/assets/icon/backTop.png')}`
          //   box.className = 'imgFiexdBtn';
          //  box.style.width = '72px'
          //  box.style.height = '72px'
          //  box.style.cursor = 'pointer'
          //  box.style.borderRadius = '50%'
          //  box.style.border = ' 1.5px solid #FFFFFF'
          //  box.style.background = 'rgba(255, 255, 255, 0.7)'
          //  box.style.boxShadow = '0px 3.42857px 17.1429px rgba(186, 186, 186, 0.2)'
          //  box.style.position = 'fixed'
          //  box.style.right = '50px'
          //   box.style.bottom = '120px'
          //   box.onclick = function () {
          //     document.documentElement.scrollTo({
          //       top:0,
          //       behavior:'smooth'
          //     })
          //   }
          //  document.body.appendChild(box)
        //  }
        // } else {
        //   if (box) {
        //     box.remove()
        //     box = null
        //   }
        // }
      })
    })
  },
  destroyed() {
    this.observer.disconnect()
  },
  computed: {
    currentPath () {
      return this.$route.path
    }
  },
  watch: {
    currentPath: {
      handler(value) {
        if (value === '/home') {
          this.bannerHeight = '822'
          //  else if (value === '/privacyPage' || value === '/statement') {
          // this.bannerHeight = '342'
          // }
        } else {
          this.bannerHeight = '342'
        }
        switch (value) {
          case '/home':
            this.bannerPic = HomeBanner
            this.crtItem.idx = 0
            break
          case '/wepage':
            this.bannerPic = ProfileBanner
            this.topText = this.$t('Home.banner.banner1')
            this.crtItem.idx = 1
            break
          case '/newsCenter':
            this.bannerPic = NewsBanner
            this.topText = this.$t('Home.banner.banner2')
            this.crtItem.idx = 3
            break
          case '/newsDetail':
            this.bannerPic = NewsBanner
            this.topText = this.$t('Home.banner.banner9')
            this.crtItem.idx = 3
            break
          case '/commonBroke/dataList1':
            this.bannerPic = BrokerBanner
            this.topText = this.$t('Home.banner.banner3')
            this.crtItem.idx = 2
            break
          case '/commonBroke/dataList2':
            this.bannerPic = InvestBanner
            this.topText = this.$t('Home.banner.banner4')
            this.crtItem.idx = 2
            break
          case '/statement':
            this.bannerPic = Disclaimers
            this.topText = this.$t('Home.banner.banner6')
            this.crtItem.idx = 0
            break
          case '/privacyPage':
            this.bannerPic = Disclaimers
            this.topText = this.$t('Home.banner.banner7')
            this.crtItem.idx = 0
            break
          case '/contactPage':
            this.bannerPic = Contact
            this.topText = this.$t('Home.banner.banner8')
            this.crtItem.idx = 0
            break
          default:
            break
        }
      },
      immediate: true
    },
    // $route: {
    //   handler (value) {
    //     if (
    //       value.name == 'Statement' ||
    //       value.name == 'PrivacyPage' ||
    //       value.name == 'ContactPage'
    //     ) {
    //       this.crtItem.idx = -1
    //       this.crtItem.moveIndx = ''
    //     }
    //   }
    // }
  },
  destroyed () {
    document.documentElement.removeEventListener('click', e => {
      this.crtItem.show = false
    })
  }
}
</script>

<style scoped>
.label_context {
  padding: 15px 0;
}
.Header {
  width: 100%;
  min-width: 1280px;
  background: rgba(9, 66, 225, 0.8);
}
.Header_box {
  height: 100%;
  width: 100%;
  padding: 0 100px;
  min-width: 1262px;
  box-sizing: border-box;
  height: 78px;
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s;
  /* background: #000; */
}
.Header_left {
  width: 182px;
  height: 50px;
  cursor: pointer;
  /* background: #ccc; */
}
.Header_right {
  position: relative;
}
.Header_right li {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  list-style-type: none;
  cursor: pointer;
  position: relative;
}
li:nth-of-type(2) {
  margin-left: 32px;
}
li:nth-of-type(3) {
  margin-left: 32px;
}
li:nth-of-type(4) {
  margin-left: 32px;
}
li:last-of-type {
  margin-left: 41px;
  position: relative;
}
/* li:hover{
    color: #F0201E;
  } */

li:last-of-type::after {
  position: absolute;
  display: block;
  content: '';
  width: 1px;
  height: 17px;
  line-height: 15px;
  background: rgba(255, 255, 255, 0.1);
  left: -16px;
  top: 2px;
}

.line {
  position: absolute;
  height: 3px;
  width: 20px;
  background-color: #f0201e;
  border-radius: 2px;
  left: 6px;
  bottom: -4px;
}

.flex_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actived {
  color: #fff ;
}
.labels:hover .label_context{
  color: #fff ;
}
.actived_than_head{
  background: #FFFFFF!important;
  box-shadow: 0px 0.5px 0.5px rgba(0, 0, 0, 0.15), 0px 4px 16px rgba(106, 113, 136, 0.05), 0px 2px 4px -1px rgba(106, 113, 136, 0.02)!important;
}
.actived_than_head .actived{
  color: #0942E1;
}
.actived_than_head .labels{
  color: #000;
}
.actived_than_head .labels:hover .label_context{
  color: #0942E1;
}
.labels:hover .detail_box {
  display: inline-block;
}
.detail_box {
  width: 164px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 0px 0.5px rgba(0, 0, 0, 0.2), 0px 4px 12px rgba(0, 0, 0, 0.08);
  display: none;
  border-radius: 4px;
  position: absolute;
  z-index: 99;
  color: #000000;
  left: 50%;
  top: 34px;
  transform: translateX(-50%);
  padding: 12px 0;
}

.detail_box .actived{
  color: #0942E1;
}

.detail_box span {
  height: 56px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.detail_box span:hover {
  color: #0942E1 !important;
}

.labels .detail_box span:hover .noneRightArrow {
  display: block;
}
.showRightArrow {
  display: inline-block;
}
.noneRightArrow {
  display: none;
}
.banner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_banner_pic {
  /* transform: translateY(-70.5px); */
  /* position: relative;
    top: -72px; */
    position: relative;
    top: -72px;
}
.top_text {
  font-weight: 600;
  font-size: 62px;
  line-height: 82px;
  text-align: center;
  color: #fff;
  letter-spacing: 5px;
}
.top_text_en {
  font-style: normal;
  font-weight: 400;
  font-size: 25.8899px;
  line-height: 150%;
  letter-spacing: 0.39em;
  color: #fff;
}
.lang_icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 6px;
  vertical-align: middle;
}
.icon-text {
  display: inline-block;
  vertical-align: middle;
}
.icon-logo {
  width: 22px !important;
  height: 22px !important;
  display: inline-block;
  margin-left: 0 !important;
  font-size: 22px;
  color: rgba(255,255,255,0.5);
}
.labels:hover .icon-logo {
  color: rgba(255,255,255,1);
}
.icon-active {
  color: rgba(0,0,0);
}
.labels:hover .icon-active {
  color: #0942E1;
}
.fiexd {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  /* background: linear-gradient(
    to left top,
    rgba(60, 20, 24, 0.85),
    rgba(10, 16, 41, 0.85)
  ); */
  background: rgba(9, 66, 225, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
}
.iconfont{
  margin-left: 10px;
}
.smallBox{
  width: 136px;
}
.imgFiexdBtn{
  position: fixed;
  transition: all 0.4s ease-out;
  right: 50px;
  bottom: 120px;
  width: 54px;
  height: 54px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.7);
  border: 1.5px solid #FFFFFF;
  box-shadow: 0px 3.42857px 17.1429px rgba(186, 186, 186, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

}
.imgFiexdBtn i {
  font-size: 36px;
  margin-left: 0;
}
.imgFiexdBtn i:hover{
 color:  #0942E1;
}
.hideBtn{
  opacity: 0;
  /* right: -70px; */
  /* display: none; */
}

.logoCircle{
  position: absolute;
  width: 1200px;
  top: -512px;
  height: 1200px;
  left: 180px;
  transform: scale(0.5);
}
</style>
