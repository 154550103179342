<template>
  <div class="foot">
    <div class="foot_container">
      <div class="top">
        <div class="logo" @click="toHome">
          <img src="@/assets/logo/new-logo-text.png" alt="" />
        </div>
        <div style="flex: 1;"></div>
        <div class="top-bottom" @click="toPage">
          {{ $t('Home.footer.concact') }}
        </div>
      </div>
      <div class="bottom">
        <div class="box">
          <span @click="toStatement" class="box_hover">{{
            $t('Home.footer.statement')
          }}</span>
          <span></span>
          <span @click="toPrivacyPage" class="box_hover">{{
            $t('Home.footer.privacy')
          }}</span>
        </div>
        <div style="flex: 1;"></div>
        <div class="copy">{{ $t('Home.footer.rights') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toStatement () {
      this.$router.push('/statement')
      document.body.scrollTop = 0
    },
    toPrivacyPage () {
      this.$router.push('/privacyPage')
      document.body.scrollTop = 0
    },
    toPage () {
      this.$router.push('/contactPage')
      document.body.scrollTop = 0
    },
    toHome () {
      this.$router.push('/home')
      document.body.scrollTop = 0
    }
  }
}
</script>

<style scoped>
.foot {
  width: 100%;
  height: auto;
  padding: 114px 0 110px;
  box-sizing: border-box;
  background: linear-gradient(0deg, #101828, #101828),
    linear-gradient(294.65deg, #3c1418 4.5%, #0a1029 66.16%);
}
.foot_container {
  margin: auto;
  width: 1280px;
  padding: 0 24px;
  box-sizing: border-box;
  height: auto;
  color: #ffffff;
}
.top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 35px;
}
.logo {
  width: 197px;
}
.logo:hover {
  cursor: pointer;
}
.top-bottom {
  width: 220px;
  height: 51px;
  border-radius: 8px;
  background: linear-gradient(93.79deg, #FF7A00 4.41%, #EF7300 101.52%);
  color: #ffffff;
  line-height: 51px;
  cursor: pointer;
}
.top-bottom:hover {
  background: #FF8A00;
}
.bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
  position: relative;
}
.bottom::before {
  position: absolute;
  content: ' ';
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  top: -35px;
  z-index: 1;
  left: 0;
}
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
}
.box > span {
  display: flex;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
}
.box > span:nth-child(2) {
  height: 12px;
  width: 1px;
  margin: 0 32px;
  opacity: 0.2;
  background-color: #ffffff;
  cursor: none;
}
.box_hover {
  border-bottom: 1px solid transparent;
}
.box_hover:hover {
  color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid white;
  /* box-sizing: border-box; */
}
.copy {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  opacity: 0.8;
}
</style>
