import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import Index from "../views/index.vue";

const routes = [
  {
    path: "/",
    name: "Index",
    hidden: true,
    component: Index,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "HomeView",
        component: () => import("@/views/HomeView.vue"),
      },
      {
        path: "/wepage",
        name: "Wepage",
        component: () => import("@/views/Wepage.vue"),
      },
      {
        path: "/newsCenter",
        name: "NewsCenter",
        component: () => import("@/views/NewsCenter.vue"),
      },
      {
        path: "/newsDetail",
        name: "NewsDetail",
        component: () => import("@/views/NewsDetail.vue"),
      },
      {
        path: "/commonBroke/:id",
        name: "CommonBroke",
        component: () => import("@/views/CommonBroke.vue"),
      },
      {
        path: "/statement",
        name: "Statement",
        component: () => import("@/views/Statement.vue"),
      },
      {
        path: "/privacyPage",
        name: "PrivacyPage",
        component: () => import("@/views/PrivacyPage.vue"),
      },
      {
        path: "/contactPage",
        name: "ContactPage",
        component: () => import("@/views/ContactPage.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from) {
    // (to.path !== from.path) && (document.body.scrollTop = 0)
    // document.body.scrollTop = 0
    document.documentElement.scrollTop = 0;
    // return { left: 0, top: 0 } // 无效？
  },
});

export default router;
