import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "@/i18n/i18n";
import "@/style/iconfont/iconfont.css";
import "@/style/index.css";
import "animate.css";
const app = createApp(App).use(router).use(i18n).mount("#app");

document.title = app.$t("Home.title.webTitle");
