import { createI18n } from "vue-i18n";
import ch from "./langs/ch";
import com from "./langs/com";

const i18n = createI18n({
  globalInjection: true,
  locale: localStorage.getItem("LANGS") || "ch", // 默认显示语言 默认简体中文
  messages: {
    ch, // 简体中文
    com, //繁体
  },
});
export default i18n;
